import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TextField, Button, Typography } from '@mui/material';
import { useKontaktContext } from './machine';
import { send } from './send';
import { flexColumn } from '../common/styled';

const Form = styled.form`
  ${flexColumn};
  align-items: center;

  > * {
    margin: var(--small);
  }
`;

export const Kontaktskjema = ({ formName }) => {
  const [sent, setSent] = useState(false);
  const [
    {
      email,
      navn,
      meldingstekst,
      navnIsInvalid,
      emailIsInvalid,
      meldingstekstIsInvalid,
    },
    dispatch,
  ] = useKontaktContext();
  const formIsValid =
    !navnIsInvalid &&
    !emailIsInvalid &&
    !meldingstekstIsInvalid &&
    email &&
    navn &&
    meldingstekst;

  const dispatchChange = ({ target }) => {
    dispatch({ type: 'value_change', name: target.name, value: target.value });
  };
  const dispatchBlur = ({ target }) => {
    dispatch({ type: 'blur', name: target.name, value: target.value });
  };

  const sendMelding = () => {
    if (formIsValid) {
      send({ navn, email, meldingstekst }).then(() => {
        setSent(true);
      });
    }
  };

  return (
    <Form name={formName} data-netlify="true">
      <input type="hidden" name="form-name" value={formName} />
      <TextField
        value={navn}
        onChange={dispatchChange}
        onBlur={dispatchBlur}
        error={navnIsInvalid}
        name="navn"
        id="Navn"
        placeholder="Ditt navn"
        label="Navn"
        helperText={navnIsInvalid && 'Fyll inn ditt navn'}
      />
      <TextField
        value={email}
        onChange={dispatchChange}
        onBlur={dispatchBlur}
        error={emailIsInvalid}
        name="email"
        id="Email"
        placeholder="din@adresse.com"
        label="Email"
        helperText={emailIsInvalid && 'Ugyldig email adresse'}
      />
      <TextField
        value={meldingstekst}
        onChange={dispatchChange}
        onBlur={dispatchBlur}
        error={meldingstekstIsInvalid}
        name="meldingstekst"
        id="melding"
        label="Melding"
        multiline
        rows={4}
        variant="filled"
        helperText={meldingstekstIsInvalid && 'Skriv en melding'}
      />
      {sent ? (
        <Typography>
          <span aria-label="Ok" role="img">
            👍
          </span>{' '}
          Melding sendt
        </Typography>
      ) : (
        <Button
          type="button"
          onClick={sendMelding}
          variant="contained"
          color="secondary"
        >
          Send forespørsel
        </Button>
      )}
    </Form>
  );
};
