import React from 'react';
import { Typography } from '@mui/material';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Kontaktskjema } from '../components/kontakt/Kontaktskjema';
import { KontaktskjemaProvider } from '../components/kontakt/machine';
import styled from '@emotion/styled';

const KontaktPage = () => {
  return (
    <Layout>
      <SEO title="Kontakt oss 😅 Sweat.no" />
      <Div>
        <Typography variant="h3" component="h1">
          Kontaktskjema
        </Typography>
        <Typography>For spørsmål eller forespørsler til Sweat.no</Typography>
        <KontaktskjemaProvider>
          <Kontaktskjema formName="kontakt-sweat" />
        </KontaktskjemaProvider>
      </Div>
    </Layout>
  );
};

export default KontaktPage;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
